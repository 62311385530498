/*
This is where you write custom SASS
*/

body[data-ajax-transitions="true"]
	#ajax-loading-screen[data-method="standard"]
	.loading-icon {
	display: block;
	opacity: 1;
	transition: 0.3s ease;
}

#ajax-loading-screen {
	background-color: #fff;
	width: 100%;
	height: 100%;
	position: fixed;
	transition: 0.3s ease;
	top: 0;
	left: 0;
	display: none;
	z-index: 1000000000;
}

.loading-icon.spin,
.portfolio-loading.spin,
.pp_loaderIcon.spin,
.default-loading-icon.spin {
	animation: .3s ease 0s normal none
    fade_in_logo;
	-webkit-animation: .3s ease 0s normal none
    fade_in_logo;
	animation-iteration-count: 1;
}

#ajax-loading-screen .loading-icon {
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@keyframes fade_in_logo {
	0% {
        transform: scale(.8);
        opacity: .8;
	}
	50% {
        transform: scale(.9);
        opacity: .9;
	}
	100% {
        transform: scale(1);
        opacity: 1;
	}
}